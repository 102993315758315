<template>
    <div v-if="assetsShown > 0" id="load-more" class="pagination__button">
        <button
            class="button button--lg button--yellow button--rounded"
            @click="loadMore()"
        >
            <span class="text-small"
                >Showing {{ assetsShown }} of {{ totalAssetsCount }}</span
            >
            <span>LOAD MORE</span>
        </button>
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        assetsShown: {
            type: Number,
            default: 0,
        },
        totalAssetsCount: {
            type: Number,
            default: 0,
        },
        pagination: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        paginate() {
            return {
                offset: this.pagination.offset,
                assetsShown: this.assetsShown,
                limit: this.pagination.limit,
                totalAssetsCount: this.totalAssetsCount,
            };
        },
    },
    methods: {
        loadMore() {
            let tryNumber = 10;
            let remainingAssets = this.totalAssetsCount - this.assetsShown;

            if (remainingAssets / tryNumber >= 1) {
                if (this.pagination.limit === 30) {
                    this.pagination.offset = 30;
                    this.pagination.limit = tryNumber;
                } else {
                    this.pagination.offset += tryNumber;
                    this.pagination.limit = tryNumber;
                }
            } else {
                if (this.pagination.limit === 30) {
                    this.pagination.offset = 30;
                    this.pagination.limit = remainingAssets;
                } else {
                    this.pagination.offset += remainingAssets;
                    this.pagination.limit = remainingAssets;
                }
            }
            this.$emit(
                'update:rowsPerPage',
                this.pagination.offset,
                this.pagination.limit
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.pagination__button {
    margin: rem(50) 0 rem(20);
    display: flex;
    justify-content: center;

    button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        width: 190px;
        height: 50px;
        border-radius: 6px;

        font-size: 1.1rem;
        font-weight: 700;
        letter-spacing: 0.92px;
        text-transform: uppercase;

        .text-small {
            font-size: 0.7rem;
            font-weight: 400;
        }
    }
}
</style>
