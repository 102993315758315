<template>
    <div v-if="assets" class="asset-net">
        <template>
            <transition-group name="fade" tag="div" class="grid">
                <div
                    v-for="(asset, index) in assets"
                    :key="`asset_card_${index}`"
                    class="grid__card"
                >
                    <asset-card :key="asset.id" :asset="asset"></asset-card>
                </div>
            </transition-group>
        </template>

        <template v-if="assets.length < totalAssetsCount" class="asset-net">
            <Pagination
                :total-assets-count="totalAssetsCount"
                :assets-shown="assets.length"
                :pagination="pagination"
                @update:rowsPerPage="changeRowsPerPage"
            />
        </template>
    </div>
</template>

<script>
import AssetCard from './AssetCard.vue';
import Pagination from '@/components/common/Pagination';

export default {
    name: 'AssetsGrid',
    components: {
        AssetCard,
        Pagination,
    },
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
        assets: {
            type: [Object, Array],
            default: () => [],
        },
        totalAssetsCount: {
            type: Number,
            default: null,
        },
        pagination: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        changeRowsPerPage(offset, limit) {
            this.$emit('update:rowsPerPage', offset, limit);
        },
    },
};
</script>

<style lang="scss" scoped>
.grid {
    display: grid;
    grid-gap: 20px;
    margin: 0 auto;

    //same as home slider
    grid-template-columns: repeat(5, minmax(200px, 1fr));

    @media (max-width: $lg-up) {
        grid-template-columns: repeat(4, minmax(200px, 1fr));
    }
    @media (max-width: $md-up) {
        grid-gap: 17px;
        grid-template-columns: repeat(3, minmax(200px, 1fr));
    }
    @media (max-width: $sm-up) {
        grid-gap: 12px;
        grid-template-columns: repeat(2, minmax(200px, 1fr));
    }
    @media (max-width: 450px) {
        grid-gap: 12px;
        grid-template-columns: repeat(1, minmax(200px, 1fr));
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity $transition-values;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
